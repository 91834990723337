import React, { ReactNode } from 'react';

import { gqlClient } from '@sus-core/api/client/sus-graphql-client';
import { ApolloProvider } from '@apollo/client/react/context/ApolloProvider';

type Props = { children?: ReactNode };

export function RootWrapper({ children }: Readonly<Props>) {
  return <ApolloProvider client={gqlClient}>{children}</ApolloProvider>;
}
