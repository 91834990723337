exports.components = {
  "component---src-pages-401-tsx": () => import("./../../../src/pages/401.tsx" /* webpackChunkName: "component---src-pages-401-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-anmelden-tsx": () => import("./../../../src/pages/anmelden.tsx" /* webpackChunkName: "component---src-pages-anmelden-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-customer-index-tsx": () => import("./../../../src/pages/customer/index.tsx" /* webpackChunkName: "component---src-pages-customer-index-tsx" */),
  "component---src-pages-konto-anlegen-tsx": () => import("./../../../src/pages/konto-anlegen.tsx" /* webpackChunkName: "component---src-pages-konto-anlegen-tsx" */),
  "component---src-pages-user-index-tsx": () => import("./../../../src/pages/user/index.tsx" /* webpackChunkName: "component---src-pages-user-index-tsx" */),
  "component---src-pages-warenkorb-tsx": () => import("./../../../src/pages/warenkorb.tsx" /* webpackChunkName: "component---src-pages-warenkorb-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-category-with-products-tsx": () => import("./../../../src/templates/category-with-products.tsx" /* webpackChunkName: "component---src-templates-category-with-products-tsx" */),
  "component---src-templates-cms-page-tsx": () => import("./../../../src/templates/cmsPage.tsx" /* webpackChunkName: "component---src-templates-cms-page-tsx" */),
  "component---src-templates-landing-page-tsx": () => import("./../../../src/templates/landingPage.tsx" /* webpackChunkName: "component---src-templates-landing-page-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

